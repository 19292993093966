import React, { Component } from "react";
import {Helmet} from 'react-helmet'

class PageHelmet extends Component{
    render(){
        return(
            <React.Fragment>
                <Helmet>
                    <title>{this.props.pageTitle} | RazyInk </title>
                    <meta name="description" content="RazyInk is a tattoo parlol and a piercing shop from Timisoara, Romania. If you want a professional tattoo done, this is the right place to be." />
                </Helmet>
            </React.Fragment>
        )
    }
}


export default PageHelmet;
