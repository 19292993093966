import React, { Component } from "react";

class About extends Component{
    render(){
        let title = 'About',
        description = 'Welcome to Razy Ink Tattoo Parlor, a local Timisoara tattoo shop! We are a blend of old school values combined with modern day safety standards. If you want a proffessional tattoo or a piercing done, this is the right place for you.';
        return(
            <React.Fragment>
                <div className="about-wrapper">
                    <div className="container">
                        <div className="row row--35 align-items-center">

                            <div className="col-lg-5 col-md-12">
                                <div className="thumbnail">
                                    <img className="w-100" src="/assets/images/service/razy-landing-picture-01.jpg" alt="About Images"/>
                                </div>
                            </div>

                            <div className="col-lg-7 col-md-12">
                                <div className="about-inner inner">
                                    <div className="section-title pt-4">
                                        <h2 className="title">{title}</h2>
                                        <p className="description">{description}</p>
                                    </div>
                                    <div className="row mt--30 mt_sm--10">
                                        <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                                            <div className="about-us-list">
                                                <h3 className="title">Tattoos</h3>
                                                <p>A tattoo is an important decision, get your dream tattoo done by experts.</p>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                                            <div className="about-us-list">
                                                <h3 className="title">Piercings</h3>
                                                <p>Do you want a cool piercing? Get your piercing done in a proffessional and sanitized enviroment.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
export default About;