import React, { Component } from "react";
import { AiFillEdit } from 'react-icons/ai';

const ServiceList = [
    {
        icon: 'tattoo-machine',
        title: 'Proffesional Tattoos',
        description: 'Get a tattoo done by an expert at RazyInk. Check our gallery and get your daily dose of inspiration!'
    },
    {
        icon: 'piercing',
        title: 'Body Piercing',
        description: 'Body piercing using ultra-hygienic methods and tools.'
    },
    {
        icon: 'repair',
        title: 'Touch-ups and remakes',
        description: 'Did you have a bad experience at another tattoo parlor? We can fix that and remake your bad tattoo.'
    },
]

class ServiceOne extends Component{
    render(){
        return(
            <React.Fragment>
                <div className="row mb-5">
                    {ServiceList.map( (val , i) => (
                        <div className="col-lg-4 col-md-6 col-sm-6 col-12" key={i}>
                            <div className="service service__style--1">
                                <div className="icon ">
                                    <img src={`/assets/images/svg/${val.icon}.svg`} alt="tattoo icons" className="service-icon"/>
                                </div>
                                <div className="content text-center">
                                    <h4 className="title">{val.title}</h4>
                                    <p>{val.description}</p>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </React.Fragment>
        )
    }
}
export default ServiceOne;