import React, { Component } from "react";
import ServiceOne from "../../elements/service/ServiceOne";

class SliderOne extends Component {
    constructor(props) {
        super(props);

        const images = [
            "assets/images/landing/tattoo-landing-1.png",
            "assets/images/landing/tattoo-landing-1.png",
            "assets/images/landing/tattoo-landing-3.png",
            "assets/images/landing/tattoo-landing-4.png"
        ];

        this.state = {
            images,
            currentImg: 0
        }
    }

    componentDidMount() {
        this.interval = setInterval(() => this.changeBackgroundImage(), 1000);
    }

    componentWillUnmount() {
        if (this.interval) {
            clearInterval(this.interval);
        }
    }

    changeBackgroundImage() {
        let newCurrentImg = 0;
        const { images, currentImg } = this.state;
        const noOfImages = images.length;

        if (currentImg !== noOfImages - 1) {
            newCurrentImg = currentImg + 1;
        }

        this.setState({ currentImg: newCurrentImg });
    }


    render() {
        const { images, currentImg } = this.state;
        const urlString = `${images[currentImg]}`;

        let divTitle = <div className="inner"> <h1 className="title theme-gradient">tattoo <br /> parlor. </h1> </div>;
        let divImage = <div className="inner image-landing-container"> <img className="landing-tattoo-image" src={urlString}/></div>;
        let divToShow;

        if (currentImg == 0) {
            divToShow = divTitle;
        } else {
            divToShow = divImage;
        }

        return (
            <div className="slider-activation">
                {/* Start Single Slide */}
                <div className="slide slide-style-1 slider-fixed--height d-flex align-items-center bg_image bg_image--1" data-black-overlay="6">
                    <div className="container position-relative">
                        <div className="row">
                            <div className="col-lg-6 ">
                                <div className="inner ">
                                    <img src="assets\images\logo\logo-footer.png" className="d-lg-none"></img>
                                    <h1 className="title theme-gradient left-landing-side d-none d-lg-block">Razy <br/> Ink</h1>
                                </div>
                            </div>
                            <div className="col-lg-6 border-left border-danger d-none d-lg-block d-xl-block">
                                {divToShow}
                            </div>
                        </div>
                        {/* Start Service Area */}
                        <div className="service-wrapper service-white">
                            <ServiceOne />
                        </div>
                        {/* End Service Area */}
                    </div>
                </div>
                {/* End Single Slide */}
            </div>
        );
    }
}
export default SliderOne;