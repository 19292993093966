// React Required
import React, { Component } from 'react';
import ReactDOM from 'react-dom';

// Create Import File
import './index.scss';

import PageScrollTop from './component/PageScrollTop';

// Home layout
import MainDemo from './home/MainDemo';
import Startup from './home/Startup';
import Paralax from './home/Paralax';
import HomePortfolio from './home/HomePortfolio';
import DigitalAgency from './home/DigitalAgency';
import CreativeAgency from './home/CreativeAgency';
import PersonalPortfolio from './home/PersonalPortfolio';
import Business from './home/Business';
import StudioAgency from './home/StudioAgency';
import CreativeLanding from './home/CreativeLanding';
import HomeParticles from './home/HomeParticles';
import DesignerPortfolio from './home/DesignerPortfolio';
import InteriorLanding from './home/Interior';
import CorporateBusiness from './home/CorporateBusiness';
import InteractiveAgency from './home/InteractiveAgency';

// Dark Home Layout 
import DarkMainDemo from './dark/MainDemo';

// Element Layout
import Service from "./elements/Service";
import ServiceDetails from "./elements/ServiceDetails";
import About from "./elements/About";
import Contact from "./elements/Contact";
import PortfolioDetails from "./elements/PortfolioDetails";
import Blog from "./elements/Blog";
import BlogDetails from "./elements/BlogDetails";
import error404 from "./elements/error404";


// Blocks Layout

import Team from "./blocks/Team";
import Portfolio from "./blocks/Portfolio";
import VideoPopup from "./blocks/VideoPopup";
import Gallery from "./blocks/Gallery";
import Brand from "./blocks/Brand";
import ContactForm from "./blocks/ContactForm";
import GoogleMap from "./blocks/GoogleMap";
import Columns from "./blocks/Columns";
import PricingTable from "./blocks/PricingTable";
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import * as serviceWorker from './serviceWorker';

import { MessengerChat } from "react-messenger-chat-plugin";

class Root extends Component {
    render() {
        return (
            <BrowserRouter basename={'/'}>
                <PageScrollTop>
                    <MessengerChat
                        pageId="435124433714163"
                        language="en_US"
                        themeColor={"#CA1A29"}
                        bottomSpacing={30}
                        loggedInGreeting="Let's chat about your tattoo"
                        loggedOutGreeting="Let's chat about your tattoo"
                        greetingDialogDisplay={"show"}
                        debugMode={true}
                        onMessengerShow={() => {
                            console.log("onMessengerShow");
                        }}
                        onMessengerHide={() => {
                            console.log("onMessengerHide");
                        }}
                        onMessengerDialogShow={() => {
                            console.log("onMessengerDialogShow");
                        }}
                        onMessengerDialogHide={() => {
                            console.log("onMessengerDialogHide");
                        }}
                        onMessengerMounted={() => {
                            console.log("onMessengerMounted");
                        }}
                        onMessengerLoad={() => {
                            console.log("onMessengerLoad");
                        }}
                    />
                    <Switch>
                        <Route exact path={`${process.env.PUBLIC_URL}/`} component={DarkMainDemo} />
                        <Route exact path={`${process.env.PUBLIC_URL}/main-demo`} component={MainDemo} />
                        <Route exact path={`${process.env.PUBLIC_URL}/dark-main-demo`} component={DarkMainDemo} />
                        <Route exact path={`${process.env.PUBLIC_URL}/startup`} component={Startup} />
                        <Route exact path={`${process.env.PUBLIC_URL}/paralax`} component={Paralax} />

                        <Route exact path={`${process.env.PUBLIC_URL}/digital-agency`} component={DigitalAgency} />
                        <Route exact path={`${process.env.PUBLIC_URL}/creative-agency`} component={CreativeAgency} />
                        <Route exact path={`${process.env.PUBLIC_URL}/personal-portfolio`} component={PersonalPortfolio} />
                        <Route exact path={`${process.env.PUBLIC_URL}/studio-agency`} component={StudioAgency} />
                        <Route exact path={`${process.env.PUBLIC_URL}/business`} component={Business} />
                        <Route exact path={`${process.env.PUBLIC_URL}/portfolio-home`} component={HomePortfolio} />
                        <Route exact path={`${process.env.PUBLIC_URL}/creative-landing`} component={CreativeLanding} />
                        <Route exact path={`${process.env.PUBLIC_URL}/home-particles`} component={HomeParticles} />
                        <Route exact path={`${process.env.PUBLIC_URL}/designer-portfolio`} component={DesignerPortfolio} />
                        <Route exact path={`${process.env.PUBLIC_URL}/interior-landing`} component={InteriorLanding} />
                        <Route exact path={`${process.env.PUBLIC_URL}/corporate-business`} component={CorporateBusiness} />
                        <Route exact path={`${process.env.PUBLIC_URL}/interactive-agency`} component={InteractiveAgency} />

                        {/* Element Layot */}
                        <Route exact path={`${process.env.PUBLIC_URL}/service`} component={Service} />
                        <Route exact path={`${process.env.PUBLIC_URL}/service-details`} component={ServiceDetails} />
                        <Route exact path={`${process.env.PUBLIC_URL}/contact`} component={Contact} />
                        <Route exact path={`${process.env.PUBLIC_URL}/about`} component={About} />
                        <Route exact path={`${process.env.PUBLIC_URL}/portfolio-details`} component={PortfolioDetails} />
                        <Route exact path={`${process.env.PUBLIC_URL}/blog`} component={Blog} />
                        <Route exact path={`${process.env.PUBLIC_URL}/blog-details`} component={BlogDetails} />

                        {/* Blocks Elements  */}
                        <Route exact path={`${process.env.PUBLIC_URL}/team`} component={Team} />
                        <Route exact path={`${process.env.PUBLIC_URL}/portfolio`} component={Portfolio} />
                        <Route exact path={`${process.env.PUBLIC_URL}/video-popup`} component={VideoPopup} />
                        <Route exact path={`${process.env.PUBLIC_URL}/gallery`} component={Gallery} />
                        <Route exact path={`${process.env.PUBLIC_URL}/clint-logo`} component={Brand} />
                        <Route exact path={`${process.env.PUBLIC_URL}/contact-form`} component={ContactForm} />
                        <Route exact path={`${process.env.PUBLIC_URL}/google-map`} component={GoogleMap} />
                        <Route exact path={`${process.env.PUBLIC_URL}/columns`} component={Columns} />
                        <Route exact path={`${process.env.PUBLIC_URL}/pricing-table`} component={PricingTable} />



                        <Route path={`${process.env.PUBLIC_URL}/404`} component={error404} />
                        <Route component={error404} />

                    </Switch>
                </PageScrollTop>
            </BrowserRouter>
        )
    }
}

ReactDOM.render(<Root />, document.getElementById('root'));
serviceWorker.register();